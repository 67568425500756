import styles from './pt-input.module.scss';
import { InputHTMLAttributes } from 'react';
import PTText from '../text/pt-text';
import TooltipOptions from 'primereact/tooltip/tooltipoptions';
import { Tooltip } from 'primereact/tooltip';

export interface PTInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'name'> {
  label?: string;
  register?: any;
  error?: string;
  name?: string;
  tooltip?: string;
  tooltipOptions?: TooltipOptions;
  containerStyles?: any;
  setValue?: any;
}

export default function PTInput({
  label,
  register,
  name,
  tooltip,
  error,
  tooltipOptions,
  containerStyles,
  maxLength,
  minLength,
  setValue,
  ...props
}: PTInputProps) {
  const tooltipClass = `${name}-${Date.now()}`;

  const handleChange = (e: any) => {
    const value = e.target.value.toString();
    if (maxLength && value.length > maxLength) {
      e.preventDefault();
      setValue && setValue(name, value.subString(0, maxLength - 1));
      return
    }
    if (minLength && value.length > minLength) {
      e.preventDefault();
      return
    }
  }

  return (
    <div
      className={`${styles.formColumn} ${styles.wCol} ${styles.wCol4}`}
      style={containerStyles}
    >
      {label && (
        <PTText asTag="h3" size="sm" weight="600" className={styles.textHeader}>
          {label}
        </PTText>
      )}
      {tooltip && <Tooltip target={`.${tooltipClass}`} />}
      <input
        {...props}
        name={name}
        maxLength={maxLength}
        minLength={minLength}
        onChange={handleChange}
        className={`${styles.formTextField}
        ${styles.wInput} ${tooltipClass}`}
        {...(register ? register(name) : {})}
        data-pr-tooltip={tooltip}
        data-pr-position={tooltipOptions?.position}
      />
      {error && (
        <small style={{ color: 'red', fontSize: '14px', marginTop: '7px' }}>
          {error}
        </small>
      )}
    </div>
  );
}
