import { useRouter } from 'next/router';
import React, { useEffect, useState, FC } from 'react';
import { signInGoogleUser, signInUser } from '@/redux/actions/session.action';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import {
  GoogleAuthProvider,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';
import { auth } from '@/utils/firebase';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './login.module.scss';
import Image from 'next/image';
import logoCompany from '@/public/logo/LogoTPTrip.png';
import Link from 'next/link';
import PTButton from '@/components/common/button/pt-button';
import Loading from '@/components/common/loading/loading';
import PTText from '@/components/common/text/pt-text';
import PTInput from '@/components/common/input/pt-input';
import { setToast } from '@/redux/slices/common.slice';
import * as yup from 'yup';
import { LoginUser } from '@/interfaces/user.interface';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Head from 'next/head';
import googleIcon from '@/public/googleIcon.png';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('No es un correo válido')
    .required('Este campo es requerido'),
  password: yup.string().required('Este campo es requerido'),
});

const SignIn: FC<LoginUser> = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginUser>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [isUserLogged, setIsUserLogged] = useState(true);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { user, error } = useAppSelector((state) => state.session);
  // const [gUser, gLoading] = useAuthState(auth);
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const [loading, setLoading] = useState(false);

  const checkIsUserLogged = () => {
    setIsUserLogged(Boolean(user));
    if (user) {
      const redirectUrl = localStorage.getItem('redirectUrl');
      router.push(redirectUrl || `/`);
    }
  };

  const handleSignIn = async (data: LoginUser) => {
    // const recaptchaValue = recaptchaRef.current?.getValue();
    // if (recaptchaValue) {
    setLoading(true);
    try {
      const { type } = await dispatch(signInUser(data));
      setLoading(false);
      if (type === 'session/signInUser/fulfilled') {
        dispatch(
          setToast({
            severity: 'success',
            summary: 'Usuario logado correctamente',
          }),
        );
        const redirectUrl = localStorage.getItem('redirectUrl');
        router.push(redirectUrl || `/`);
      } else if (type === 'session/signInUser/rejected') {
        dispatch(
          setToast({
            severity: 'error',
            summary: 'Usuario o contraseña no validos',
          }),
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        setToast({
          severity: 'error',
          summary: error,
        }),
      );
    }
  };

  const googleProvider = new GoogleAuthProvider();
  const handleSignInGoogle = async () => {
    try {
      setLoading(true);
      const result: UserCredential = await signInWithPopup(
        auth,
        googleProvider,
      );
      const { type } = await dispatch(signInGoogleUser(result.user));
      if (type === 'session/signInGoogleUser/fulfilled') {
        dispatch(
          setToast({
            severity: 'success',
            summary: 'Logado correctamente',
          }),
        );
        router.push('/');
      }
    } catch (error) {
      dispatch(
        setToast({
          severity: 'error',
          summary: 'Error',
          detail: error,
        }),
      );
    }
  };

  useEffect(checkIsUserLogged, [router, router.pathname]);

  useEffect(() => {
    if (!loading && user) {
      const redirectUrl = localStorage.getItem('redirectUrl');
      router.push(redirectUrl || `/`);
      setIsRedirecting(true);
    }
  }, [user, router, loading]);

  if (loading || isRedirecting) {
    return <Loading isLoading={loading} />;
  }

  // const handlerecaptcha = (value: any) => {
  //   console.log('Captcha value:', value);
  // };

  return (
    <>
      <Head>
        <title>TP Trip - Login</title>
      </Head>
      <div className={styles.container}>
        <Loading isLoading={loading} />
        <div className={styles.cardContent}>
          <div className={styles.containerClose}>
            <Image src={logoCompany} height={130} alt="logoCompany" />
            <div>
              <Link href="/">
                <PTText size='xs' weight='400' className={styles.textClosePage}><i className={`pi pi-times ${styles.iconClose}`}></i>Cerrar</PTText>
              </Link>
            </div>
          </div>

          <div className={styles.containerHeader}>
            <PTText
              asTag="h2"
              size="lg"
              weight="600"
              className={styles.titleSignUp}
            >
              Ingresar
            </PTText>
            <div className={styles.subHeader}>
              <PTText
                asTag="h5"
                size="sm"
                weight="500"
                className={styles.countText}
              >
                ¿Aún no tienes una cuenta?
              </PTText>
              <Link href="/registro" className={styles.linkSignIn}>
                <PTText size='sm' weight='600'>Regístrate</PTText>
              </Link>
            </div>
          </div>

          <div className={styles.containerForm}>
            <div className={styles.signUpWithForm}>
              <PTInput
                register={register}
                className={styles.inputs}
                name="email"
                type="email"
                placeholder="Email"
                label="Email"
                error={errors.email?.message}
                containerStyles={{ width: '100%' }}
              />
              <PTInput
                register={register}
                className={styles.inputs}
                name="password"
                type="password"
                placeholder="Password"
                label="Password"
                error={errors.password?.message}
                containerStyles={{ width: '100%' }}
              />
              {/* <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Lcj9H8kAAAAAO1EXHjGueHubA1yVhvxYpW1gs2t"
              onChange={handlerecaptcha}
              className={styles.recaptcha}
            /> */}
            </div>

            <div
              className={styles.signUpWithSocialMedia}
              onClick={handleSignInGoogle}
            >
              <div className={styles.signUpWithGoogle}>
                <div className={styles.googleImageContainer}>
                  <Image src={googleIcon} height={20} alt="googleIcon" />
                </div>
                <div className={styles.googleTextContainer}>
                  <PTText
                    size="sm"
                    weight="600"
                    asTag="h3"
                    className={styles.textButton}
                  >
                    Ingresar con Google
                  </PTText>
                </div>
              </div>
            </div>
          </div>

          <PTButton
            size="lg"
            isMain={false}
            onClick={handleSubmit(handleSignIn)}
            className={styles.buttonSignIn}
          >
            Ingresar
          </PTButton>
        </div>
      </div>
    </>
  );
};

export default SignIn;
